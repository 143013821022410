import React from 'react'
import '../component/Popup.scss'

class Popup extends React.Component{
    render(){        
        return(
            <div className={"popup "+ this.props.type} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}

class SpecPopup extends React.Component {
    render() {
        return (
            <Popup type="infobox" style={{width: "25rem"}}>
                <div className="popupText-col1">
                    제품명 <br /> 
                    제품크기 <br />
                    무게 <br />
                    최대출력크기 <br />
                    평균출력속도 <br />
                    출력 해상도 <br />
                    정격전압 <br />
                    최대소비전력 <br />
                    사용온도 <br />
                    연결
                </div>
                <div className="popupText-col2">
                    C-10<br />
                    202 x 211 x 349 (mm)<br />
                    6.06 kg<br />
                    지름 3.2 cm 원형, 높이 5 cm<br />
                    50 ~ 60 mm/h (크라운 1개 12~13분)<br />
                    XY: 47.25μm, Z: 50,100μm<br />
                    220 V<br />
                    65 W<br />
                    25 ± 5 ℃<br />
                    USB 2.0, Ethernet, Wi-Fi
                </div>
            </Popup>
        )
    }
}

class NoticePopup extends React.Component {
    render() {
        return (
            <Popup type="infobox" style={{width: "30rem"}}>
                <div>제3자배정 유상증자 공고</div>
                <div class="popupText-spacer"></div>
                <div>본 회사는 상법 제418조에 따라 2020년 3월 16일 이사회에서 아래와 같은 내용과 같이 제3자배정방식에 의한 신주발행을 결의하였으며 이에 따라 본 회사의 주주분들께 신주발행절차를 진행함을 공고하는 바입니다.</div>
                <div class="popupText-spacer"></div>
                <div>1. 신주식의 종류와 수: 기명식 보통주식 1,000주</div>
                <div>2.	신주식의 발행가액: 1주의 금액 금200,000원</div>
                <div>3.	납입기일: 2020.4.10.</div>
                <div>4.	납입은행 및 지점명: 우리은행 대전북지점</div>
                <div>5.	신주식인수방법: 정관 제9조의 제2항의 4호에 의하여 주주의 신주인수권을 배제하고 제3자에게 배정한다</div>
                <div>6.	기타 신주발행에 관해 필요한 절차사항은 대표이사에게 일임한다.</div>
                <div class="popupText-spacer"></div>
                <div>2020년 3월 17일</div>
                <div>주식회사 힉스</div>
                <div>대전광역시 유성구 신성로61번안길 6, 1층</div>
                <div>대표이사 김종범</div>
            </Popup>
        )
    }
}

export default Popup
export {SpecPopup}
export {NoticePopup}