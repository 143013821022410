import React from "react";

import "./Footer.scss";

const Footer = () => {
    return (
        <div className="main-footer-wrapper">
            <span className="main-footer-company">HiX</span>
            &nbsp;
            <span className="main-footer-copyright">
                &copy; Copyright 2021. All Rights Reserved.
            </span>
        </div>
    );
};

export default Footer;