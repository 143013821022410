import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

import './../component/Feature.scss'
import './../component/Text.scss'

class Feature extends React.Component {
    render() {
        return (
            <div className="feature-withDesc">
                <div className="feature-withDesc-img-wrapper">
                    <img src={ this.props.src } 
                         className="feature-withDesc-img" 
                         alt={ this.props.title } />
                </div>
                
                <div className="text feature-withDesc-title">
                    { this.props.title }
                </div>

                <div className="text feature-withDesc-content">
                    { this.props.content }
                </div>
            </div>
        );
    }
}

class FeatureOnlyTitle extends React.Component {
    render() {
        return (
            <div className="feature-onlyTitle">
                <ScrollAnimation animateIn="fadeIn" delay={ this.props.delay }>
                    <div className="feature-onlyTitle-img-wrapper">
                        <img src={ this.props.src } className="feature-onlyTitle-img" alt={ this.props.title } />   
                    </div>

                    <div className="text feature-onlyTitle-title">
                        { this.props.title }
                    </div>
                </ScrollAnimation>
            </div>
        );
    }
}

export default Feature
export {FeatureOnlyTitle}