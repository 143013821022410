import React, { Component } from "react";
import NavigationMenu, {MenuButton} from '../component/NavigationMenu'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import EditPage from "../component/blog/EditPage";
import PostPage from "../component/blog/PostPage";
import ListPage from "../component/blog/ListPage";

class BlogContainer extends Component {
    render() {
        return (
            <div>
                <NavigationMenu>
                    <MenuButton type="link" className="nav-link" to="/">메인</MenuButton>
                    <MenuButton type="link" className="nav-link" to="/news">공지 및 뉴스</MenuButton>
                    <MenuButton type="link" className="nav-link" to="/support">고객지원</MenuButton>
                </NavigationMenu>
                <Switch>
                    <Route exact path="/:category" component={ListPage} />
                    <Route exact path="/post/edit" component={EditPage} />
                    <Route path="/post/:id" component={PostPage} />
                </Switch>
            </div>
        )
    }
}

export default BlogContainer;