import React from "react";
import axios from 'axios';
import {MDBTable, MDBTableHead, MDBTableBody, MDBNav, MDBNavItem} from 'mdbreact'
import { Link } from 'react-router-dom';
import { paginate } from './utils/paginate';

import './EditPage.scss'

class PageNav extends React.Component {
    constructor(props) {
        super(props);

    }

    render(){
        const { itemsCount, pageSize, currentPage, onPageChange } = this.props;
        const pageCount = Math.ceil(itemsCount / pageSize); 
        
        // if (pageCount === 1) return null; // 1페이지 뿐이라면 페이지 수를 보여주지 않음
        const pageNumbers = [];
        for (let i = 1; i <= pageCount; i++) {
            pageNumbers.push(i);
        }
        
        return(
            <MDBNav className="justify-content-center">
                <ul className="pagination pg-blue">
                    {pageNumbers.map(page => (
                        <MDBNavItem key={page} className={page === currentPage ? "page-item active" : "page-item"}>
                            <Link className="page-link" onClick={() => onPageChange(page)}>
                                {page}
                                <span className="sr-only">(current)</span>
                            </Link>
                        </MDBNavItem>
                    ))}
                </ul>
            </MDBNav>

        );
    }
}


class ListPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            posts:[],
            currentPage: 1,
            prevCategory:''
        }
    }

    componentDidMount(){
        this.setState({prevCategory: '', currentPage: 1})
    }
    
    componentDidUpdate() {
        if(this.props.match.params.category != this.state.prevCategory)
        {
            axios.get(`https://services.hix.co.kr/post/?category=` + this.props.match.params.category)
            .then(res => {
                const post = res.data;
                this.setState({ posts: post.reverse() });
            })
            .catch( error => {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            });
            this.setState({prevCategory: this.props.match.params.category})
        }
    }

    handlePageChange = (page) => {
        this.setState({currentPage: page})
    }

    render(){
        const pagedPost = paginate(this.state.posts, this.state.currentPage, 10);

        return(
            <div className="container">
                <MDBTable>
                    <MDBTableHead>
                        <tr>
                            <th>No.</th>
                            <th>Category</th>
                            <th>Title</th>
                            <th>Date</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        { pagedPost.map(post => 
                            <tr key={post.id}>
                                <td>{post.id}</td>
                                <td>{post.category}</td>
                                <td><Link to={'/post/' + post.id} >{post.title}</Link></td>
                                <td>{post.date}</td>
                            </tr>
                        )}
                    </MDBTableBody>
                </MDBTable>

                <PageNav 
                    itemsCount={this.state.posts.length}
                    pageSize={10}
                    currentPage={this.state.currentPage} 
                    onPageChange={this.handlePageChange}
                />
            </div>
        );
    }
}

export default ListPage