import React, { Component, Fragment } from "react";

import Footer from "../../component/Footer/Footer";
import Contact from '../../component/Contact'

class FooterContainer extends Component {
    render() {
        return (
            <div>
                <Contact />
                <Footer />
            </div>
        )
    }
}

export default FooterContainer;