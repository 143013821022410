import React from 'react'
import Logo from './../../assets/logo-blue.png'

import '../component/NaviagationMenu.scss'
import '../component/Text.scss'


import {MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, 
        MDBNavLink, MDBNavbarToggler, MDBCollapse} from 'mdbreact';

class MenuButton extends React.Component {
    GoTo = (input) => {
        console.log(input);
        
        window.scrollTo({ top: input-60, behavior: 'smooth' })
    }

    render(){
        const Button = this.props.type === 'scroll' ? 
                        <MDBNavLink className="text" to="" onClick={() => this.GoTo(this.props.to)}>
                            {this.props.children}
                        </MDBNavLink>
                        :
                        <MDBNavLink className="text" to={this.props.to} >
                            {this.props.children}
                        </MDBNavLink>
        return(
                <MDBNavItem>
                    {Button}
                </MDBNavItem>
        )
    }
}

class NavigationMenu extends React.Component {
    state = {
        isOpen: false
    };
      
    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render(){        
        return(
            <MDBNavbar color="white" light expand="lg" fixed="top" >
                <MDBNavbarBrand onClick={() => {window.location.href="https://hix.co.kr"}}>
                    <img src={Logo} alt="HiX" 
                                    style={{"margin": "0.3vh 0 0.3vh 9vw",
                                            "width": "8vw",
                                            "height": "2.5vw",
                                            "minWidth": "6.4rem",
                                            "minHeight": "2rem"}}/>
                </MDBNavbarBrand>

                <MDBNavbarToggler onClick={this.toggleCollapse} />
                <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
                    <MDBNavbarNav right style={{"marginRight": "10vw"}}>
                        {this.props.children}
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>                
        );
    }
}

export default NavigationMenu
export {MenuButton}