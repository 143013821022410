import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

import '../component/Background.scss'

class Background extends React.Component {
    render(){
        return(
            <div className={"background " + this.props.type} style={{background: `url(${this.props.src})`}}>
                <div className={this.props.titleClass}>{this.props.title}</div>
                {this.props.children}
            </div>
        );
    }
}

class ProductBackground extends React.Component {
    render(){
        return(
            <>
                <img className={"background " + this.props.type} src={this.props.src} />
                {this.props.children}
            </>
        );
    }
}

class ProductBackgroundWithTitle extends React.Component {
    render(){
        return(
            <ProductBackground type={this.props.type} src={this.props.src}>
                <div className={this.props.titleClass}>{this.props.titleText}</div>
                {this.props.children}
            </ProductBackground>
        );
    }
}

export default Background
export { ProductBackground, ProductBackgroundWithTitle}
