import React from 'react'
import Button from 'react-bootstrap/Button';
import ScrollAnimation from 'react-animate-on-scroll';

import { ProductBackground, ProductBackgroundWithTitle } from './../component/Background'
import { SpecPopup } from '../component/Popup'

import Dent from "./../../assets/dent.png";
import Cap from "./../../assets/cap.png";
import Bean1 from "./../../assets/product-bg-1.png";
import Bean2 from "./../../assets/product-bg-2.png";

import '../component/Product.scss'
import '../component/Text.scss'

class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false
        };
    }
    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    render() {
        return (
            <div id='product' ref={this.props.inputRef} className="product">
                <ProductBackgroundWithTitle type="product-bg-1" 
                                            src={Bean1}
                                            titleClass="text product-title"
                                            titleText="힉스가 만드는 제품들">

                    <div className="product-item">
                        <div className="product-item-left">
                            <ScrollAnimation animateIn="fadeIn" >
                            <img className="product-item-img-dent" src={Dent} />
                            </ScrollAnimation>
                            {/* <img className="product-item-img-model" src={Model} /> */}
                        </div>

                        <div className="product-item-right">
                            <div className="text product-name">DentSlicer</div>
                            <div className="text product-description">치의료용 3D 모델 슬라이싱 프로그램입니다. 지나치게 복잡한 여러 종류의 프로그램을 필요로 했던 기존 과정에서 벗어나 단일 프로그램으로 프린트 세팅을 마칠 수 있습니다. 자체 개발한 CAD/CAM 엔진을 탑재하여 타 CAD 프로그램 대비 @@% 저렴한 가격대를 형성하였습니다.</div>
                            <div className="product-item-buttonwrapper">
                            <Button bsPrefix="button">제품 사이트로 이동 &nbsp;&#xE001;</Button>
                            </div>
                        </div>
                    </div>
                </ProductBackgroundWithTitle>

                <ProductBackground type="product-bg-2" src={Bean2}>
                    <div className="product-item">                    
                        <div className="product-item-right" id="cap-mobile">
                            <ScrollAnimation animateIn="fadeIn" >
                            <img className="product-item-img-cap" src={Cap} />
                            </ScrollAnimation>
                        </div>

                        <div className="product-item-left">
                            <div className="text product-name">C-10</div>
                            <div className="text product-description">편의성을 극대화한 캡슐형 3D 프린터입니다. 교체 가능한 프린팅 베드의 도입으로 기존의 번거로운 세척 과정을 개선했습니다. 일회용 커피 캡슐처럼 간편하게 사용하는 출력 재료 캡슐은 세척 없이 다양한 재료를 오가는 프린팅을 가능하게 합니다.</div>
                            <div className="product-item-buttonwrapper">
                                <Button bsPrefix="button"
                                        onFocus={this.togglePopup.bind(this)}
                                        onBlur={this.togglePopup.bind(this)}>
                                    제품 사양 보기 &nbsp;&#xE001;
                                </Button>
                                {/* <Button className="product-item-button" bsPrefix="product-btn">더 자세히 문의하기 &ensp;&#xE001;</Button> */}
                                {this.state.showPopup ?
                                    <SpecPopup />
                                    : null
                                }
                            </div>
                        </div>
                        <div className="product-item-right" id="cap-web">
                            <ScrollAnimation animateIn="fadeIn" >
                            <img className="product-item-img-cap" src={Cap} />
                            </ScrollAnimation>
                        </div>
                    </div>
                </ProductBackground>

            </div>
        )
    }
}

export default Product;