import React, { Component } from "react";
import Button from 'react-bootstrap/Button';

// import ReactDOM from 'react-dom';
import "animate.css/animate.min.css";
import '../component/Button.scss'
import Particles from 'react-particles-js';
import NavigationMenu, {MenuButton} from '../component/NavigationMenu'
import ScrollAnimation from 'react-animate-on-scroll'


import "./Main.scss";

import About from './About/About'
import Product from '../component/Product'
import History from '../component/History'
import Contact from '../component/Contact'

const particleOption = {
    particles: {
        number: {
            value: 80
        },
        color: {
            value: "#2bc2ff"
        },
        size: {
            value: 3,
            anim: {
                speed: 4
            }
        },
        line_linked: {
            enable: false,
            color: "#2bc2ff"
        }
    }
}

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutPosition: null,
            productPosition: null,
            noticePosition: null,
            navColor: 'rgba(0,0,0,0)',
            videoHeight: 0
        }
    }

    listenScrollEvent = (e) => {
        if (window.scrollY == 0) {
            this.setState({ navColor: 'rgba(0,0,0,0)' })
        } else if (window.scrollY < 100) {
            this.setState({ navColor: 'rgba(0,0,0,0)'})
        } else {
            this.setState({ navColor: 'rgba(255,255,255)'})
        }
    }

    updateDimensions = (aboutY, productY) => {
        this.setState({
            aboutPosition: aboutY,
            productPosition: productY,
        })
    }

    componentDidMount() {
        // const videoHeight = this.videoRef.clientHeight;
        const videoHeight = 1000;
        this.setState({ videoHeight });

        this.setState({
            aboutPosition: this.aboutElement.offsetTop,
            productPosition: this.productElement.offsetTop,
        })
        window.addEventListener("resize", () => this.updateDimensions(this.aboutElement.offsetTop, this.productElement.offsetTop));     
        window.addEventListener("scroll", this.listenScrollEvent)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => this.updateDimensions(this.aboutElement.offsetTop, this.productElement.offsetTop));
    }

    render() {
        
        var aboutPos = this.state.aboutPosition;
        var productPos = this.state.productPosition;
        
        return (
            <div>
                <NavigationMenu>
                    <MenuButton type="scroll" to={aboutPos}>회사소개</MenuButton>
                    <MenuButton type="scroll" to={productPos}>개발제품</MenuButton>
                    <MenuButton type="link" to="/news">공지 및 뉴스</MenuButton>
                    <MenuButton type="link" to="/support">고객지원</MenuButton>
                </NavigationMenu>


                <div className="header">
                    <div className="header-title-wrapper" >
                        <ScrollAnimation animateIn='fadeIn' delay="200" animateOnce={true}>
                            Experience
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn' delay="1000" animateOnce={true}>
                            Better
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn' delay="1800" animateOnce={true}>
                            3D Printing
                        </ScrollAnimation>
                        <Button bsPrefix="button main" onClick={() => this.GoTo(productPos)}>힉스의 솔루션 보러 가기 &nbsp; &#10095;</Button>
                    </div>
                </div>
                
                {/* <Header /> */}
                <About inputRef={el => this.aboutElement = el} />
                <Product inputRef={el => this.productElement = el} />
                <History />
            </div>
        )
    }
}

export default Main