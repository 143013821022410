import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import NavigationMenu, {MenuButton} from '../../component/NavigationMenu'
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import "../../component/Main.scss";
import axios from 'axios';
import cookie from 'react-cookies'

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export function PricingCard(props) {
  
  useEffect(() => {
    props.tiers.map((tier) =>
      {tier.description.map((line, idx) => (
        console.log(line)))
      }
    )
  });

  return (
    <>
      {props.tiers.map((tier) => (
        // Enterprise card is full width at sm breakpoint
        <Grid item key={tier.pricing_id} xs={12} sm={12} md={4}>
        <Card>
          <CardHeader
          title={tier.product_name}
          subheader=''
          titleTypographyProps={{ align: 'center' }}
          subheaderTypographyProps={{ align: 'center' }}
          action={null}
          className={props.classes.cardHeader}
          />
          <CardContent>
          <div className={props.classes.cardPricing}>
              <Typography component="h2" variant="h3" color="textPrimary">
              ₩{tier.price}
              </Typography>
              { 
                props.filter != "One-Off" &&
                <Typography variant="h6" color="textSecondary">
                  /month
                </Typography>
              }
          </div>
          <ul>
            {tier.description.map((line, idx) => (
              <Typography component="li" variant="subtitle1" align="center" key={line[idx+1]}>
                  {line[idx+1]}
              </Typography>
            ))}
          </ul>
          </CardContent>
          <CardActions>
            <Button fullWidth variant="outlined" color="primary" onClick={ () => window.location = `https://hix.co.kr/checkout/${tier.pricing_id}` }>
                View Detail
            </Button>
          </CardActions>
        </Card>
        </Grid>
      ))}
    </>
  )
}

export default function PurchasePlans() {
  const result = [];
  const classes = useStyles();
  const [tiers, setTiers] = useState([]);
  const [filter, setFilter] = useState('One-Off');

  const handleChange = (event) => {
    setFilter(event.target.value);
    
  };

  useEffect(() => {
    axios.get(`https://services.hix.co.kr/product/get_plans?filter=` + filter)
    .then(res => {
      for (var item  = 0; item < res.data.length; item++){
        result.push(res.data[item]);
      }
      console.log(result[0].description[0][1])
      setTiers(result)
    })
    .catch(() => {
      setTiers(undefined)
    });
  }, [filter],);

  return (
    <div>
      {/* navigation bar */}
      <NavigationMenu>
        <MenuButton type="link" className="nav-link" to="/">메인</MenuButton>
        <MenuButton type="link" className="nav-link" to="/news">공지 및 뉴스</MenuButton>
        <MenuButton type="link" className="nav-link" to="/support">고객지원</MenuButton>
      </NavigationMenu>

      <div className="container">
        {/* select box */}
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={filter}
            onChange={handleChange}
          >
            <MenuItem value={"One-Off"}>One-Off</MenuItem>
            <MenuItem value={"Monthly"}>Monthly</MenuItem>
            <MenuItem value={"Yearly"}>Yearly</MenuItem>
          </Select>
        </FormControl>

        {/* plan cards */}
        <CssBaseline />
        <Container maxWidth="md" component="main" className="pt-5 pb-5">
          <Grid container alignItems='center' justify='center' spacing='3'>
            {
              tiers != undefined ?
              <PricingCard tiers={tiers} filter={filter} classes={classes} />:
              <h1>Comming Soon...</h1>
            }
          </Grid>
        </Container>
      </div>
    </div>
  );
}