import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './index.css';
import App from './App';
import About from './main/component/About/About'
import BlogContainer from './main/container/BlogContainer'
import * as serviceWorker from './serviceWorker';
import FooterContainer from "./main/container/Footer/FooterContainer";

import PurchasePlans from "./main/component/pricing/Plans";
import Checkout from "./main/component/pricing/Checkout"

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'

ReactDOM.render(
    <Router>
        <Switch>
          <Route exact path="/" component={App} />
          <Route path="/plans" component={PurchasePlans} />
          <Route path="/checkout/:id" component={Checkout} />
          <Route path="/post" component={BlogContainer} />

          {/* post categories */}
          <Route path="/support" component={BlogContainer} />
          <Route path="/news" component={BlogContainer} />
        </Switch>
        <FooterContainer />
    </Router>
      , 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
