import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import Feature from '../Feature'
import Background from '../Background'
import Popup from '../Popup'

import './../../component/Text.scss'

import Skill from './../../../assets/ic-skill.png';
import Tech from './../../../assets/ic-tech.png';
import User from './../../../assets/ic-user.png';
import BackgroundImg from './../../../assets/about-bg.png'

class About extends React.Component {
    render() {
        return (
            <div id="about" ref={this.props.inputRef} className="about">
                <Background type="about" 
                            src={BackgroundImg}
                            titleClass="text about-phrase"
                            title={<React.Fragment>"주식회사 힉스는 <span className="text-bold">3D 프린팅 종합 솔루션</span>을 제공합니다"</React.Fragment>}>
                    
                    <ScrollAnimation animateIn="fadeIn" animateOnce="true" >
                        <Popup type="about">
                                <Feature src={ Skill }
                                        title="Skilled experts" 
                                        content="2014년 Delta형 3D프린터 Neuron을 개발하여 국내 최초로 상용화한 이후 3D프린터와 CAD/CAM 소프트웨어를 개발해오고 있습니다." />
                                
                                <Feature src={ Tech }
                                        title="Technical ability" 
                                        content="하드웨어와 소프트웨어를 자체 연구 및 개발하는 기술력으로 더 빠르고 정밀한 3D 프린팅을 위해 노력합니다." />
                                
                                <Feature src={ User }
                                        title="User centered" 
                                        content="실제 사용자의 입장을 고려하여 기존 프로세스에 존재했던 불편한 점들을 분석하고 새롭게 개선합니다." />        
                        </Popup>
                    </ScrollAnimation>
                </Background>
                
            </div>
            
        )
    }
    
}

export default About