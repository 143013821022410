import React, { Component } from "react";

import Main from "./../component/Main";

class MainContainer extends Component {
    render() {
        return (
            <div>
                <Main />
            </div>

        )
    }
}

export default MainContainer;