import React from 'react'
import Background from './../component/Background'

import ContactBackground from "./../../assets/contact-bg.png"
import WhiteLogo from "./../../assets/logo-white.png";

import './../component/Text.scss'
import './../component/Contact.scss'

class Contact extends React.Component {
    render() {
        return (
                <Background type="contact" src={ContactBackground}>
                    
                    <img className="contact-logo" src={WhiteLogo} />
                    <p className="text contact-mail">support@hix.co.kr</p>    
                    <ul className="terms-list">
                        <li><a className ="text terms-link" href="https://services.hix.co.kr/privacy">Privacy Policy</a></li>
                        <li><a className ="text terms-link" href="https://services.hix.co.kr/terms">Terms of Service</a></li>
                    </ul>

                </Background>
        );
    }
}

export default Contact