import React from "react";
import axios from 'axios';
import {MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from 'mdbreact'
import ReactHtmlParser from 'react-html-parser';
import Button from 'react-bootstrap/Button';

import './EditPage.scss'

class PostPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            post:[],
        }
    }
    
      componentDidMount() {
        axios.get(`https://services.hix.co.kr/post/${this.props.match.params.id}`)
         .then(res => {
            const post = res.data;
            this.setState({ post: post });
          })
          .catch( error => {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        });
      }
      
    render(){
        return(
            <div className="container">
                <MDBRow>
                    <MDBCol>
                        <MDBCard>
                            <MDBCardBody cascade className="text-center">
                                <MDBCardTitle className="font-weight-bold" tag="h1">{this.state.post.title}</MDBCardTitle>
                                <p className='container-inner text-right text-black-50'>
                                    {this.state.post.category}
                                    &emsp;
                                    {this.state.post.date}
                                </p>
                                
                                <MDBCardText>
                                    {ReactHtmlParser(this.state.post.content)}
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <div className="container-inner">
                    <Button bsPrefix="button" style={{"width": "20vw"}} 
                            onClick={()=>{window.location = "https://hix.co.kr/support"}}>
                        목록으로
                    </Button>
                </div>
            </div>
        );
    }
}

export default PostPage