import React from 'react';
import Button from 'react-bootstrap/Button';
import cookie from 'react-cookies'

import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";

import axios from 'axios';

import {MDBInput} from 'mdbreact'
import './EditPage.scss'

class DraftEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          editorState: EditorState.createEmpty(),
        };
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }

    onEditorStateChange(editorState){
        this.setState({
            editorState,
        });
        this.props.onContentChange(stateToHTML(editorState.getCurrentContent()));
    };

    imageUploadCallBack = file => new Promise(
        (resolve, reject) => {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          let img = new Image();
          reader.onload = function (e) {
            img.src = this.result
          }
          img.onload = function () {
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(img, 0, 0, canvas.width, canvas.height);
            var newUrl = canvas.toDataURL('image/jpeg', 0.92);//base64 format
            
            resolve({
              data: {
                link: newUrl
              }
            })
          }
        }
      );    

    render() {
        return (
            <Editor
                editorState={this.state.editorState}
                editorClassName="editor"
                onEditorStateChange={this.onEditorStateChange}
                toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    image: { uploadCallback: this.imageUploadCallBack, previewImage: true },
                }}
            />
        );
    }
}

class EditPage extends React.Component {   
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.handleContentChange = this.handleContentChange.bind(this);

        this.state = {
            title: '',
            content: '',
            category:'',
        }
    }

    componentDidMount(){
        if(!cookie.load('username'))
        {
            window.location = "https://services.hix.co.kr/product/login?next=https://hix.co.kr/post/edit";
        }
    }

    handleContentChange(newContent) {
        this.setState({content: newContent});
    }

    handleSubmit = event => {
        event.preventDefault();

        const post = {
            title: this.state.title,
            content: this.state.content,
            category: this.state.category,
        };
    
        axios.post(`https://services.hix.co.kr/post/edit`, post, 
            {headers: {'x-csrftoken': cookie.load('csrftoken')}, withCredentials: true})
        .then(res => {
            alert("Post done");
            window.location = "https://hix.co.kr/support";
        })
        .catch( error => {
            if(error.response.status == 403)
            {
                alert("Permission denied. Please login again");
                window.location = "https://services.hix.co.kr/product/login?next=https://hix.co.kr/support/edit";
            }
        });
    }

    render(){
        return(
            <>
            <form id="post" onSubmit={this.handleSubmit}>
                <div className="container">
                    <div className="container-inner-horizon">
                        <div className="container-inner-horizon-left" style={{"marginRight": "1vw", "width": "30vw"}}>
                            <select form="post" className="browser-default custom-select" onChange={e => this.setState({category: e.target.value})}>
                                <option>Choose your option</option>
                                <option value="support">Support</option>
                                <option value="news">news</option>
                                <option value="3">Option 3</option>
                            </select>
                        </div>
                        <div className="container-inner-horizon-right">
                            <Button type="submit" bsPrefix="button" style={{"width": "20vw"}}>
                                Post
                            </Button>
                        </div>
                    </div>
                    <div className="container-inner">
                        <MDBInput label="Title" outline size="lg" onChange={e => this.setState({title: e.target.value})}/>
                    </div>
                    <div className="container-inner">
                        <DraftEditor 
                            content={this.state.content} 
                            onContentChange={this.handleContentChange}
                        />
                    </div>
                </div>
            </form>
            </>
        );
    }
}

export default EditPage