import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

import Background from './../component/Background'
import { FeatureOnlyTitle } from './../component/Feature'

import Delta from "./../../assets/history-delta.png";
import DLP from "./../../assets/history-dlp.png";
import SW from "./../../assets/history-sw.png";
import HistoryBackground from "./../../assets/history-bg.png"
import Patent from "./../../assets/history-patent.png";
import Timeline from "./../../assets/history-timeline.png";

import './../component/Text.scss'
import './../component/History.scss'


class History extends React.Component {
    render() {
        return (
            <Background type="history" 
                        src={HistoryBackground}
                        titleClass="text history-text"
                        title="힉스는 다년간 축적된 기술력을 토대로 3D 프린팅 시장에 도전하고 있습니다.">
                
                <FeatureOnlyTitle id="myid" src={Delta} title="Delta형 3D프린터" delay="400"/>
                <FeatureOnlyTitle src={DLP} title="DLP 3D프린터" delay="800"/>
                <FeatureOnlyTitle src={SW} title="치의료용 CAD/CAM SW" delay="1200"/>
                <FeatureOnlyTitle src={Patent} title="3D프린팅 관련 특허" delay="1600"/>
                
                <ScrollAnimation animateIn="fadeIn">
                    <div><img className="history-timeline" src={Timeline} /></div>
                </ScrollAnimation>
                
            </Background>
        );
    }
}

export default History