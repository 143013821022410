import React from 'react';
import { MDBCard, MDBCol, MDBRow } from 'mdbreact';
import $ from 'jquery'
import axios from 'axios';
import cookie from 'react-cookies'

import "../../component/Main.scss";
import "../../component/Text.scss"
import "./Checkout.scss"
import NavigationMenu, {MenuButton} from '../../component/NavigationMenu'

import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class Checkout extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            cards: [],
            cardIndex: 1,
            maxCardIndex: 1,
            plan_details: [],
            plan_description: [],
            save_checked: false,
        }
    }

    slice(startIndex){
        const result = String(this.state.plan_details.method);
    }

    handleButtonClick(isLeft){
        if (isLeft){
            this.setState({cardIndex : this.state.cardIndex - 1});
        } else {
            this.setState({cardIndex : this.state.cardIndex + 1});
        }
    }

    do_payment(card_id){
        var data = new FormData();
        data.append('billing_id', card_id)
        data.append('save', this.state.save_checked)

        axios.post(`https://services.hix.co.kr/order/?id=${this.props.match.params.id}`, data, 
        {headers: {'x-csrftoken': cookie.load('csrftoken')}, withCredentials: true})
        .then(res => {
            window.open(res.data.receipt_url, '_blank', 'resizable=1, width=500, height=825')
            window.location = 'https://services.hix.co.kr/order/thank-you'
        })
        .catch(error => {
            console.log(error);
            window.alert("Payment Error")
        })
    }

    componentDidMount(){
        axios.get('https://services.hix.co.kr/', 
                { withCredentials: true})
        .then(() =>{
            console.log(cookie.load('username'))
            if (!cookie.load('username')) {
                window.location ="https://services.hix.co.kr/product/login/?next="+ window.location.href
            }
        })
        
        $("#rightArrow").click(function(e) {
            $(".cardItem").animate({ 
                left: "-=327px",
          }, "fast" );
        });
        $("#leftArrow").click(function(e) {
            $(".cardItem").animate({ 
                left: "+=327px",
          }, "fast" );
        });
        
        axios.get('https://services.hix.co.kr/product/get_cards/', 
                    { withCredentials: true})
        .then(res =>{
            this.setState({cards: res.data, maxCardIndex: res.data.length-1})
        })
        .catch( error => {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        });

        axios.get(`https://services.hix.co.kr/product/get_plan/${this.props.match.params.id}`)
        .then(res => {
            this.setState({plan_details: res.data, plan_description: res.data.description})
        })
        .catch(error => {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        })
        console.log(cookie.load('username'))
    }

    render(){
        const buttonStyle = {
            cardL: {
                position: "absolute",
                zIndex: 10,
                top: "50%",
                transform: "translateY(-50%)",
                left: "0px",
                width: "95px",
                height: "200px",
                background: "#8080800F",
                border: "none",
                visibility: this.state.cardIndex > 1 ? "visible" : "hidden"
            },
            cardR: {
                position: "absolute",
                zIndex: 10,
                top: "50%",
                transform: "translateY(-50%)",
                right: "0px",
                width: "95px",
                height: "200px",
                background: "#8080800F",
                border: "none",
                visibility: 
                    this.state.cardIndex <= this.state.maxCardIndex + 1?         
                        "visible" : "hidden"
            }
        }

        const cardPositions = new Map(); 
        cardPositions.set("삼성카드","0px 0px");
        cardPositions.set("신한카드", "-292px 0px");
        cardPositions.set("현대카드", "-584px 0px");
        cardPositions.set("KB국민카드", "-876px 0px");
        cardPositions.set("롯데아멕스카드", "-1168px 0px");
        cardPositions.set("하나카드", "0px 180px");
        cardPositions.set("하나카드(외환)", "-292px 180px");
        cardPositions.set("BC카드", "-584px 180px");

        return(
            <div>
                <NavigationMenu>
                    <MenuButton type="link" className="nav-link" to="/">메인</MenuButton>
                    <MenuButton type="link" className="nav-link" to="/news">공지 및 뉴스</MenuButton>
                    <MenuButton type="link" className="nav-link" to="/support">고객지원</MenuButton>
                </NavigationMenu>
                <br />
                <div className="container">
                    <div>
                        <Typography component="h1" variant="title1" align="center">
                            <b className="text-bold">
                                {this.state.plan_details.product_name}
                            </b>
                        </Typography>
                    </div>
                    <div className="col-sm-9 d-flex align-items-end flex-column">
                        <Typography component="h4" variant="title2" align="right">
                            { 
                                this.state.plan_details.method != "One-Off" ?
                                    this.state.plan_details.method == "Monthly" ?
                                        this.state.plan_details.price + "/Month"
                                    : this.state.plan_details.method == "Yearly" ?
                                        this.state.plan_details.price + "/Year"
                                    : <></>
                                : this.state.plan_details.price
                            }
                        </Typography>
                        <Typography component="h4" variant="title2" align="right">
                            {this.state.plan_details.method}
                        </Typography>
                    </div>
                    <div className="d-flex justify-content-center">
                        <ul className=" col-sm-6">
                            {this.state.plan_description.map((line, idx) => (
                                <Typography className="text about" component="li" variant="h6" align="justify" key={line[idx+1]}>
                                    {line[idx+1]}
                                </Typography>
                            ))}
                        </ul>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="viewport">
                            <MDBRow start className="cardList">
                                <MDBCol style={{maxWidth: "129px", minWidth: "129px"}}></MDBCol>
                                { this.state.cards.map(card =>
                                    <MDBCol className="cardItem" style={{maxWidth: "327px", minWidth: "327px"}}>
                                        <MDBCard style={{backgroundPosition: cardPositions.get(card.card_name)}}>                                                
                                            <div className="cardText">
                                                {card.card_name}<br/>
                                                {card.card_number.toString().replace(/(.{4})/g, '$1 ')}
                                            </div>
                                        </MDBCard>
                                    </MDBCol>
                                )}
                                <MDBCol className="cardItem" style={{backgroundPosition: "-1168px 180px"}}>
                                    <button className="paycard" onClick={()=> window.location = `https://services.hix.co.kr/order?id=${this.props.match.params.id}&save=${this.state.save_checked}`}/>
                                    {/* <FormControlLabel
                                    control={
                                    <Checkbox
                                        color="primary"
                                        inputProps={{ 'aria-label': 'Save card info' }}
                                        onChange={(event) =>{
                                            this.setState({save_checked: event.target.checked})
                                        }}
                                    />}
                                    label="결제 정보 저장" /> */}
                                </MDBCol>
                            </MDBRow>
                            <button id="leftArrow" 
                                    onClick={() => this.handleButtonClick(true)} 
                                    style={buttonStyle.cardL}>
                                <h1 style={{color:"black"}}>&lt;</h1>
                            </button>
                            <button id="rightArrow" 
                                    onClick={() => this.handleButtonClick(false)}
                                    style={buttonStyle.cardR}>
                                <h1 style={{color:"black"}}>&gt;</h1>
                            </button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <button className="orderBtn" onClick={() => {
                                const card_id = this.state.cards[this.state.cardIndex - 1]
                                if (card_id)
                                    this.do_payment(card_id.billing_key)
                                else
                                    window.location = `https://services.hix.co.kr/order?id=${this.props.match.params.id}&save=${this.state.save_checked}`
                            }}>
                            <b>주문하기</b>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Checkout;